import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card4 = _resolveComponent("Card4")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.counters, (counter) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col col-sm-3 col-md-4 mb-3",
        key: counter
      }, [
        _createVNode(_component_Card4, {
          title: counter.title,
          body: counter.body
        }, null, 8, ["title", "body"])
      ]))
    }), 128))
  ]))
}