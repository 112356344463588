import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-551f9758"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "me-5" }
const _hoisted_2 = { "aria-label": "Pagination" }
const _hoisted_3 = { class: "pagination pagination-sm justify-content-end" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", {
          class: _normalizeClass({ 'page-item': true, disabled: $props.currentPage === 1 })
        }, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($props.prevPage && $props.prevPage(...args))),
            class: "page-link"
          }, "Previous")
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.totalPage, (pageNumber) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "page-item",
            key: pageNumber
          }, [
            _createElementVNode("button", {
              onClick: ($event: any) => ($props.goToPage(pageNumber)),
              class: _normalizeClass({ 'page-link': true, active: pageNumber == $props.currentPage })
            }, _toDisplayString(pageNumber), 11, _hoisted_4)
          ]))
        }), 128)),
        _createElementVNode("li", {
          class: _normalizeClass({ 'page-item': true, disabled: $props.currentPage === $props.totalPage })
        }, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($props.nextPage && $props.nextPage(...args))),
            class: "page-link"
          }, "Next")
        ], 2)
      ])
    ])
  ]))
}