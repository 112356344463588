
import Card4 from "./cards/Card4.vue";

export default {
  name: "Counters",
  props: ["counters"],
  components: {
    Card4
  }
};
