import store from "@/store/index";
import { computed } from "vue";
import { boolean } from "yup";

const currentUser = computed(() => store.getters.currentUser);

export const isPaidSuspended = () => {
  if (currentUser.value.role === "admin") return false;
  if (
    currentUser.value.stripe.subscription.id &&
    currentUser.value.stripe.subscription.status === "suspended"
  )
    return true;

  return false;
};

export const isAssistantManager =
  currentUser.value.role === "manager" && currentUser.value.manager
    ? true
    : false;

export const userStatus = (user): string => {
  if (
    !user.stripe.subscription.id &&
    user.daysLeft <= 0 &&
    ["trialing", "suspended"].includes(user.stripe.subscription.status)
  )
    return "Trial Expired";

  if (
    user.stripe.subscription.nickname === "QA Test Subscription" &&
    user.stripe.subscription.status === "suspended"
  ) {
    return "Trial Expired";
  }

  if (
    user.stripe.subscription.id &&
    user.stripe.subscription.status === "suspended"
  ) {
    return "Suspended";
  }

  if (
    user.stripe.subscription.id &&
    user.stripe.subscription.status === "canceling"
  )
    return "Canceling";

  if (
    user.stripe.subscription.id &&
    user.stripe.subscription.status === "canceled"
  )
    return "Cancelled";

  if (
    Object.hasOwn(user, "account_status") &&
    ["deleted", "unassigned"].includes(user.account_status)
  )
    return user.account_status;

  if (user.status === "removed" || !user.lastLogin) return "Invited";

  return "Active";
};

export const hasSeats = (u): boolean =>
  ["trialing", "active"].includes(u.stripe.subscription.status) &&
  u.status !== "removed" && u.account_status !== "unassigned";

export const isAssigned = (user): boolean =>
  (user.stripe.subscription.status === "active" ||
    !user.stripe.subscription.id) &&
  ["active", "allowed"].includes(user.status);

export const isTrialAndExpired = (user): boolean => {
  if (user.stripe.subscription.status !== "trialing") return true;
  if (user.stripe.subscription.status === "trialing" && user.daysLeft <= 0) return false;

  return true;
};

export const companyName = (user): string => {
  if (typeof user.companyName === "undefined") return "";
  const managerCompany = ((user || {}).manager || {}).companyName || "";
  return (user.role === "manager" && user.manager) || user.role === "member"
    ? managerCompany
    : user.companyName;
};

export const assignedSeats = (managerId, membersList): number => {
  if (!membersList) return 0;

  return membersList.filter(
    (user) => user.manager && user.manager.id === managerId && isAssigned(user)
  ).length;
};

export const unAssignedSeats = (user, assignedSeats): number => {
  const quantity =
    (((user || {}).stripe || {}).subscription || {}).quantity || 0;

  return quantity - assignedSeats;
};

export const selectedStatus = (user, metricStatus): boolean => {
  if (metricStatus) {
    if (metricStatus == "Trial in Progress") {
      if (
        !user.stripe.subscription.id &&
        user.daysLeft > 0 &&
        ["trialing"].includes(user.stripe.subscription.status)
      ) {
        return true;
      }
    }

    if (userStatus(user) == metricStatus) {
      return true;
    }

    return false;
  }

  return true;
};

export const isDeleteAllowed = (user): boolean => {
  const notAllowedStatus = ["Unassigned", "Active", "Canceling"];
  if (notAllowedStatus.includes(userStatus(user))) return false;

  return true;
};
