
import ApiService from "@/core/services/ApiService";
import {
  computed,
  onMounted,
  ref,
  defineAsyncComponent,
  watch,
  onBeforeMount
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Pagination from "../../components/Pagination.vue";
import { AxiosError } from "axios";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { ElSelect, ElOption } from "element-plus";
import {
  userStatus,
  hasSeats,
  isAssigned,
  isTrialAndExpired,
  companyName,
  selectedStatus,
  isAssistantManager,
  isPaidSuspended,
  isDeleteAllowed
} from "@/core/helpers/decorator";
import Counters from "@/components/Counters..vue";
import moment from "moment-timezone";
import { version } from '../../core/helpers/documentation';
export default {
  setup() {
    const title = "USERS";
    const store = useStore();
    const route = useRoute();
    const showInvite = ref(false);
    const showAddSeatsModal = ref(false);
    const showGrantAccessModal = ref(false);
    const showTrialExpiredModal = ref(false);
    const showChangeCardModal = ref(false);
    const userQuery = ref("");
    const userList: any = ref([]);
    const parsleyUserList: any = ref([]);
    const initialUserList: any = ref([]);
    const tableLoading = ref(true);
    const showPagination = ref(false);
    const searching = ref(false);
    const totalCount = ref(5);
    const currentPage = ref(1);
    const member = ref("");
    const listLimit = ref(50);
    const totalAssignedSeats = ref(0);
    const totalUnassignedSeats = ref(0);
    const sortColumn: any = ref("");
    const asc: any = ref(true);
    const disabledActionBtns = ref(false);
    const suspendedAccount = ref(false);
    const metricStatus = ref("");
    const currentUser = computed(() => store.getters.currentUser);
    const isOpen = ref(false);
    const reseting = ref(false);
    const totalPaidSeats = ref(0);
    const totalSubscriptions = ref(0);
    const totalPage = computed(() => {
      return Math.ceil(userList.value.length / listLimit.value);
    });

    const impersonating = computed(() => store.getters.getImpersonateToken);

    const toggleSearch = val => {
      isOpen.value = val;
    };

    const metricsOptions = ref([
      {
        value: "",
        label: "Default"
      },
      {
        value: "Active",
        label: "Active"
      },
      {
        value: "Cancelled",
        label: "Cancelled"
      },
      {
        value: "Invite",
        label: "Invite"
      }
    ]);

    const membersList: any = ref([]);
    const userSubscription =
      ((currentUser.value || {}).stripe || {}).subscription || {};

    const sortTableBy = col => {
      currentPage.value = 1;
      userQuery.value = "";
      if (sortColumn.value === col) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        asc.value = !asc.value;
      } else {
        asc.value = true;
        sortColumn.value = col;
      }

      const ascending = asc.value;
      if (col === "companyName") {
        parsleyUserList.value.sort(function(a, b) {
          // equal items sort equally
          const isEmptyA =
            companyName(a) === null ||
            companyName(a) === undefined ||
            companyName(a) == "";
          const isEmptyB =
            companyName(b) === null ||
            companyName(b) === undefined ||
            companyName(b) == "";

          if (isEmptyA && !isEmptyB) {
            return 1;
          } else if (!isEmptyA && isEmptyB) {
            return -1;
          } else if (companyName(a) > companyName(b)) {
            return ascending ? 1 : -1;
          } else if (companyName(a) < companyName(b)) {
            return ascending ? -1 : 1;
          }
          // If both are empty or both are non-empty, sort by age in ascending order
          return 0;
        });
      } else if (col === "status") {
        parsleyUserList.value.sort(function(a, b) {
          if (userStatus(a) > userStatus(b)) {
            return ascending ? 1 : -1;
          } else if (userStatus(a) < userStatus(b)) {
            return ascending ? -1 : 1;
          }
          return 0;
        });
      } else {
        parsleyUserList.value.sort(function(a, b) {
          if (a[col] > b[col]) {
            return ascending ? 1 : -1;
          } else if (a[col] < b[col]) {
            return ascending ? -1 : 1;
          }
          return 0;
        });
      }
    };

    const toggleOrder = () => {
      sortTableBy(sortColumn.value);
    };

    const getParsleyUserList = async () => {
      try {
        const { data } = await ApiService.get("/v1/user/parsley-users-list");
        let rawData = data.data;

        if (
          currentUser.value.role === "manager" &&
          !currentUser.value.manager
        ) {
          rawData = rawData.filter(
            user =>
              user.id === currentUser.value.id ||
              (user.manager &&
                user.manager.id ===
                  (isAssistantManager
                    ? currentUser.value.manager
                    : currentUser.value.id))
          );
        } else if (
          currentUser.value.role === "manager" &&
          currentUser.value.manager
        ) {

          rawData = rawData.filter(
            user =>
              user.id === currentUser.value.manager ||
              (user.manager && user.manager.id === currentUser.value.manager)
          );
        }
        rawData = rawData.filter(user => user.account_status != "deleted");
        tableLoading.value = false;
        parsleyUserList.value = rawData.map(item => {
          if (!Object.hasOwn(item, "companyName")) {
            item.companyName = null;
          }

          return item;
        });
        initialUserList.value = rawData;
        totalCount.value = rawData.length;
        userList.value = rawData;
        sortTableBy("firstName");
        membersList.value = parsleyUserList.value.filter(
          user =>
            user.role === "member" &&
            ["allowed", "active"].includes(user.status)
        );
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        if (
          message === "Request failed with status code 401" ||
          message === "Request aborted"
        ) {
          setTimeout(() => (window.location.href = "/#/"), 1000);
        } else {
          Swal.fire({
            icon: "error",
            text: message || "An error occured.",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        }
        console.log({ error });
      }
    };

    const getTotalAssignedSeats = () => {
      if (currentUser.value.role === "admin") {
        totalAssignedSeats.value = parsleyUserList.value.filter(
          user => hasSeats(user) && selectedStatus(user, metricStatus.value)
        ).length;
      } else {
        totalAssignedSeats.value = parsleyUserList.value.filter(user =>
          hasSeats(user)
        ).length;
      }
    };

    const getTotalUnassignedSeats = async () => {
      if (totalPaidSeats.value > totalAssignedSeats.value) {
        totalUnassignedSeats.value =
          totalPaidSeats.value - totalAssignedSeats.value;
      } else {
        totalUnassignedSeats.value =
          totalAssignedSeats.value - totalPaidSeats.value;
      }

      if (currentUser.value.manager) {
        const { data } = await ApiService.get(
          `/v1/user/user-info/${currentUser.value.manager}`
        );
        const assistantManager = data.data;
        const totalQty =
          "subscription" in assistantManager.stripe &&
          "quantity" in assistantManager.stripe.subscription
            ? assistantManager.stripe.subscription.quantity
            : 0;

        totalUnassignedSeats.value = totalQty - totalAssignedSeats.value;
      }

      // if (totalUnassignedSeats.value == 0) {
      //   totalUnassignedSeats.value = parsleyUserList.value.filter(
      //     user => !hasSeats(user)
      //   ).length;
      // }
    };
    const getTotalSubscriptions = async () => {
      if (currentUser.value.role === "admin") {
        const { data } = await ApiService.get(`/v1/subscription/count`);
        totalSubscriptions.value = data.data;
      }
    }

    const getTotalPaidSeats = async () => {
      if (currentUser.value.role !== "admin") {
        totalPaidSeats.value =
          (((currentUser.value || {}).stripe || {}).subscription || {})
            .quantity || 0;

        if (currentUser.value.manager) {
          const { data } = await ApiService.get(
            `/v1/user/user-info/${currentUser.value.manager}`
          );
          const assistantManager = data.data;
          totalPaidSeats.value =
            "subscription" in assistantManager.stripe &&
            "quantity" in assistantManager.stripe.subscription
              ? assistantManager.stripe.subscription.quantity
              : 0;
        }
      } else {
        totalPaidSeats.value = parsleyUserList.value.filter(
          user =>
            user.stripe.subscription.id &&
            selectedStatus(user, metricStatus.value)
        ).length;
      }
    };

    const counters = computed(() => {
      return [
        {
          title: currentUser.value.role === "admin" ? totalSubscriptions.value : totalPaidSeats.value,
          body: "Paid Seats"
        },
        {
          title: totalAssignedSeats.value,
          body: "Assigned Seats"
        },
        {
          title: totalUnassignedSeats.value,
          body: "Unassigned Seats"
        }
      ];
    });

    watch(metricStatus, async (newValue, oldValue) => {
      if (newValue != oldValue) {
        await getTotalPaidSeats();
        await getTotalAssignedSeats();
        await getTotalUnassignedSeats();
      }
    });

    const isAccountDeleted = user => {
      if (currentUser.value.role === "admin") return true;
      return (
        Object.hasOwn(user, "account_status") &&
        user.account_status !== "deleted"
      );
    };

    onMounted(async () => {
      tableLoading.value = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { step, ats_resp, ats } = route.query;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (ats_resp && ats_resp === "success") {
        const updateAtsInUse = userData =>
          ApiService.put(`/v1/user/${userData.ats}/use`, userData.data);

        await updateAtsInUse({
          ats: ats,
          data: { inUse: true }
        });

        Swal.fire({
          text:
            " Great, your Parsley account is ready to go! Make sure to download the Parsley Chrome Extension.",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Download",
          confirmButtonColor: "#3085d6",
          cancelButtonText: "Continue",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(res => {
          if (res.isConfirmed) {
            window.open(
              "https://chrome.google.com/webstore/detail/parsley/cbfchibhpgejkjjgbmibehnkimompmgc",
              "_blank"
            );
            window.location.href = "/#/users";
          } else {
            window.location.href = "/#/users";
          }
        });
      }

      await getParsleyUserList();

      if (
        impersonating.value ||
        (currentUser.value &&
          currentUser.value.ats &&
          (currentUser.value.ats.jobadder.accessToken ||
            currentUser.value.ats.bullhorn.accessToken ||
            currentUser.value.ats.vincere?.accessToken))
      ) {
        showGrantAccessModal.value = false;
      } else {
        showGrantAccessModal.value = true;
      }

      if (
        currentUser.value.role === "manager" &&
        (currentUser.value.stripe.subscription.status === "canceled" ||
          isPaidSuspended())
      ) {
        disabledActionBtns.value = true;
      }

      if (currentUser.value.role === "admin") await getTotalSubscriptions();
      await getTotalPaidSeats();
      await getTotalAssignedSeats();
      await getTotalUnassignedSeats();

      // eslint-disable-next-line @typescript-eslint/camelcase
      currentUser.value.daysLeft = moment(
        currentUser.value.role === "manager"
          ? currentUser.value.stripe.subscription.nextBilling
          : currentUser.value.manager
          ? currentUser.value.manager.stripe.subscription.nextBilling
          : moment()
      ).diff(moment(), "days");

      if (
        !impersonating.value &&
        currentUser.value.role === "manager" &&
        userStatus(currentUser.value) === "Trial Expired" &&
        !currentUser.value.stripe.subscription.id 
      ) {
        console.log('>>> SHOW TRIAL EXPIRED MODAL')
        showTrialExpiredModal.value = true;
      }

    
      suspendedAccount.value =
        !userSubscription.id &&
        ((((currentUser.value || {}).stripe || {}).subscription || {}).status ||
          "active") === "suspended";

      if (
        step &&
        !impersonating.value &&
        !currentUser.value.manager &&
        currentUser.value.role === "manager"
      ) {
        showAddSeatsModal.value = true;
        showTrialExpiredModal.value = false;
      }

      if (showGrantAccessModal.value) {
        showAddSeatsModal.value = false;
        showTrialExpiredModal.value = false;
      }
    });

    onBeforeMount(async () => {
      if (currentUser.value.role === "member") {
        window.location.href = "/#/member";
      }
    });

    const reloadUserTable = async () => {
      userQuery.value = "";
      currentPage.value = 1;
      asc.value = false;
      setTimeout(() => window.location.reload(), 500);
    };

    const updateRole = async userData =>
      await ApiService.put("/v1/user/update-role", userData);

    const assignAdmin = async user => {
      try {
        if (
          currentUser.value.role !== "admin" &&
          user.manager.id !==
            (isAssistantManager
              ? currentUser.value.manager
              : currentUser.value.id)
        )
          throw Error("Invalid user!");

        const msg =
          "Make this is user an Admin? Admin's have permission to do everything in the portal, including managing billing";
        const subMsg =
          "Done! User is now an Admin. The User may need to log out and back in, in order to see the changes.";
        Swal.fire({
          text: msg,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }).then(async result => {
          if (result.isConfirmed) {
            const { data } = await updateRole({
              userId: user.id,
              role: "manager",
              managerId: currentUser.value.id
            });

            if (data.success) {
              Swal.fire({
                text: subMsg,
                icon: "success",
                confirmButtonText: "Close",
                confirmButtonColor: "#3085d6"
              }).then(res => {
                if (res.isConfirmed) {
                  reloadUserTable();
                }
              });
            }
          }
        });
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "An error occured.",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };

    const removeAdmin = async user => {
      try {
        if (
          currentUser.value.role !== "admin" &&
          user.manager.id !==
            (isAssistantManager
              ? currentUser.value.manager
              : currentUser.value.id)
        )
          throw Error("Invalid user!");

        const msg =
          "Remove Admin privileges from this user account? They will no longer be able to make changes in the portal.";
        const subMsg =
          "Done! User is no longer an Admin. They can still use the Chrome Extension if they are assigned to a seat.";
        Swal.fire({
          text: msg,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }).then(async result => {
          if (result.isConfirmed) {
            const { data } = await updateRole({
              userId: user.id,
              role: "member",
              managerId: currentUser.value.id
            });

            if (data.success) {
              Swal.fire({
                text: subMsg,
                icon: "success",
                confirmButtonText: "Close",
                confirmButtonColor: "#3085d6"
              }).then(res => {
                if (res.isConfirmed) {
                  reloadUserTable();
                }
              });
            }
          }
        });
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "An error occured.",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };

    const updateAssignment = async (data, action) => {
      return await ApiService.put(
        action === "assign"
          ? "/v1/subscription/assign-seat"
          : "/v1/subscription/unassign-seat",
        data
      );
    };

    const updateAccountStatus = async userData =>
      await ApiService.put("/v1/user/update-account-status", userData);

    const assignSeat = async user => {
      const { id, manager } = user;
      tableLoading.value = true;
      try {
        if (totalAssignedSeats.value >= totalPaidSeats.value) {
          Swal.fire({
            icon: "error",
            text: "You have already used all of your seats.",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });

          tableLoading.value = false;
          return;
        }

        if (user.manager && user.role === "manager") {
          if (
            currentUser.value.role !== "admin" &&
            user.manager.id !==
              (isAssistantManager
                ? currentUser.value.manager
                : currentUser.value.id)
          )
            throw Error("Invalid user!");
        }

        const { data } = await updateAssignment(
          { memberId: id, managerId: manager ? manager.id : null },
          "assign"
        );

        if (data.success) {
          await updateAccountStatus({
            userId: id,
            accountStatus: "active"
          });

          Swal.fire({
            text:
              "Done! Seat assigned successfully. Log in instructions will be emailed to this user.",
            icon: "success",
            confirmButtonText: "Close",
            confirmButtonColor: "#3085d6"
          }).then(res => {
            if (res.isConfirmed) {
              reloadUserTable();
            }
          });
        }
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }
        tableLoading.value = false;

        Swal.fire({
          icon: "error",
          text: message || "An error occured.",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };

    const unassignSeat = async user => {
      const { id, manager } = user;
      tableLoading.value = true;
      try {
        if (user.manager && user.role === "manager") {
          if (
            currentUser.value.role !== "admin" &&
            user.manager.id !==
              (isAssistantManager
                ? currentUser.value.manager
                : currentUser.value.id)
          )
            throw Error("Invalid user!");
        }

        const msg =
          "Are you sure you want to unassign this seat? This user will no longer be able to use the Parsley Chrome Extension.";
        Swal.fire({
          text: msg,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }).then(async result => {
          if (result.isConfirmed) {
            const { data } = await updateAssignment(
              { memberId: id, managerId: manager ? manager.id : null },
              "unassign"
            );

            if (data.success) {
              await updateAccountStatus({
                userId: id,
                accountStatus: "unassigned"
              });

              Swal.fire({
                title:
                  "Done! Please assign your open seat to another user, or decrease your paid seats in the billing portal.",
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: "Close",
                confirmButtonColor: "#3085d6"
              }).then(res => {
                if (res.isConfirmed) {
                  reloadUserTable();
                }
              });
            }
          }
        });
      } catch (error) {
        let message;
        tableLoading.value = false;
        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "An error occured.",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };

    const deleteUser = async user => {
      try {
        if (!isDeleteAllowed(user))
          throw new Error(
            "This account is currently active and cannot be deleted until their stripe subscription is cancelled.   Please first remove their stripe subscription, and then try again"
          );
        if (user.role === "manager" && user.role === currentUser.value.role)
          throw new Error(
            "You are not allowed to delete your own account here! Please go to Settings to delete your account."
          );

        const { id, manager } = user;

        // if (
        //   currentUser.value.role !== "admin" &&
        //   manager.id !==
        //     (isAssistantManager
        //       ? currentUser.value.manager
        //       : currentUser.value.id)
        // )
        //   throw new Error("Invalid user!");

        const msg =
          "Are you sure you want to delete this user? They will no longer be able to use Parsley.";
        Swal.fire({
          text: msg,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }).then(async result => {
          if (result.isConfirmed) {
            const { data } = await updateAccountStatus({
              userId: user.id,
              accountStatus: "deleted"
            });

            const removeFromManager = async userData =>
              await ApiService.put(`/v1/user/delete/${user.id}`, userData);

            if (data.success) {
              await removeFromManager({});

              Swal.fire({
                title:
                  "Done! User has been deleted and will no longer be able to use Parsley. You can adjust your seat totals in the billing area if necessary",
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: "Close",
                confirmButtonColor: "#3085d6"
              }).then(res => {
                if (res.isConfirmed) {
                  reloadUserTable();
                }
              });
            }
          }
        });
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "An error occured.",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };

    const handleInviteMember = () => {
      showInvite.value = true;
    };

    const handleShowInvite = show => {
      showInvite.value = show;
    };

    const handleResendEmail = async user => {
      const memberId = user.id;
      try {
        const sendInvite = memberData =>
          ApiService.post("/v1/team/jobadder/resend-invite", memberData);

        const { data } = await sendInvite({
          userId: memberId
        });

        if (data.success) {
          await Swal.fire({
            icon: "success",
            title:
              "Email Invite sent! If the user does not receive it. please ask them to check their spam folder",
            showConfirmButton: true,
            confirmButtonText: "Close",
            confirmButtonColor: "#3085d6"
          });

          reloadUserTable();
        }
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "An error occured.",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };

    const nextPage = () => (currentPage.value += 1);

    const prevPage = () => (currentPage.value -= 1);

    const goToPage = page => {
      if (page !== currentPage.value) {
        currentPage.value = page;
        console.log("page", page);
      }
    };

    const handleSearch = isSearching => {
      searching.value = isSearching;
    };

    const updateUserList = data => (userList.value = data);

    const searchUser = computed(() => {
      let list = parsleyUserList.value;
      const startIndex = (currentPage.value - 1) * listLimit.value;
      const endIndex = startIndex + listLimit.value;
      if (metricStatus.value) {
        list = list.filter(user => selectedStatus(user, metricStatus.value));
      }

      if (userQuery.value) {
        const hold: any = [];
        handleSearch(true);

        for (const item in list) {
          if (
            (list[item].firstName + " " + list[item].lastName)
              .toLowerCase()
              .indexOf(userQuery.value.toLowerCase()) != -1 ||
            list[item].email
              .toLowerCase()
              .indexOf(userQuery.value.toLowerCase()) != -1
          ) {
            hold.push(list[item]);
          }
        }

        updateUserList(hold);
        return hold.slice(startIndex, endIndex);
      } else {
        handleSearch(false);
        updateUserList(list);
        return list.slice(startIndex, endIndex);
      }
    });

    const handleSort = e => {
      sortTableBy(e.target.value);
    };

    const handleShowAddSeatsModal = show => {
      showAddSeatsModal.value = show;
    };

    const handleShowGrantAccessModal = show => {
      showGrantAccessModal.value = show;
    };

    const handleShowTrialExpiredModal = show => {
      showTrialExpiredModal.value = show;
    };

    const handleShowChangeCardModal = () => {
      showTrialExpiredModal.value = false;
      showChangeCardModal.value = true;
    };

    const closeChangeCardModal = () => showChangeCardModal.value = false;

    const colSpan = () => {
      if (["member", "manager"].includes(currentUser.value.role)) return 7;

      return 8;
    };

    const impersonate = async user => {
      const { id } = user;
      try {
        store.dispatch(Actions.SHOWLOADER_MODAL, true);

        const dispatchUser = userData =>
          store.dispatch(Actions.IMPERSONATE_USER, userData);

        await dispatchUser({ userId: id });

        setTimeout(() => {
          location.href = user.role === "member" ? "/#/member" : "/#/users";
        }, 1000);
        setTimeout(() => location.reload(), 2500);
      } catch (error) {
        store.dispatch(Actions.SHOWLOADER_MODAL, false);
        Swal.fire({
          text: Array.isArray(store.getters.getErrors)
            ? store.getters.getErrors[0]
            : store.getters.getErrors.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: ["invalid_grant", "invalid_request"].includes(
            store.getters.getErrors.error
          )
            ? "Reauthenticate Ats"
            : "Okay",
          showCancelButton: ["invalid_grant", "invalid_request"].includes(
            store.getters.getErrors.error
          ),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
            cancelButton: "btn fw-bold btn-light"
          }
        }).then(({ isConfirmed }) => {
          if (
            (store.getters.getErrors.error == "invalid_grant" ||
              store.getters.getErrors.error == "invalid_request") &&
            isConfirmed
          ) {
            window.location.href = `${process.env.VUE_APP_API_URL}/v1/user/jobadder/auth?token=${store.getters.getErrors.token}&ref=${location.href}`;
          }
        });
      }
    };

    return {
      title,
      counters,
      userQuery,
      hasSeats,
      currentPage,
      totalPage,
      goToPage,
      nextPage,
      prevPage,
      totalCount,
      searching,
      showPagination,
      handleInviteMember,
      handleResendEmail,
      member,
      showInvite,
      handleShowInvite,
      assignAdmin,
      removeAdmin,
      assignSeat,
      unassignSeat,
      deleteUser,
      userList,
      searchUser,
      userStatus,
      handleSort,
      isOpen,
      toggleSearch,
      totalAssignedSeats,
      updateRole,
      isAssigned,
      currentUser,
      showAddSeatsModal,
      handleShowAddSeatsModal,
      showGrantAccessModal,
      showTrialExpiredModal,
      handleShowGrantAccessModal,
      handleShowTrialExpiredModal,
      reseting,
      isTrialAndExpired,
      tableLoading,
      companyName,
      colSpan,
      asc,
      toggleOrder,
      sortTableBy,
      disabledActionBtns,
      suspendedAccount,
      impersonate,
      sortColumn,
      metricsOptions,
      metricStatus,
      isAccountDeleted,
      impersonating,
      isPaidSuspended,
      handleShowChangeCardModal,
      showChangeCardModal,
      closeChangeCardModal,
    };
  },
  components: {
    Pagination,
    ElSelect,
    ElOption,
    InviteMember: defineAsyncComponent(() =>
      import("@/components/modals/InviteMember.vue")
    ),
    AddSeatsModal: defineAsyncComponent(() =>
      import("@/components/modals/AddSeatsModal.vue")
    ),
    GrantAccessModal: defineAsyncComponent(() =>
      import("@/components/modals/GrantAccessModal.vue")
    ),
    TrialExpiredModal: defineAsyncComponent(() =>
      import("@/components/modals/TrialExpiredModal.vue")
    ),
    Counters,
    ChangeCardModal: defineAsyncComponent(() =>
      import("@/components/modals/ChangeCardModal.vue")
    ),
  },
};
